<template>
  <div class="uc-modal" style="display: block" @click="close">
    <div :class="`uc-modal_content uc-message ${type} mb-32`" @click="keepOpen">
      <a href="javascript:void(0)" class="uc-message_close-button" @click="close">
        <i class="uc-icon">close</i>
      </a>
      <div class="uc-message_body">
        <h2 class="mb-24">
          <i class="uc-icon warning-icon mr-4">{{ icon }}</i>
          <span v-html="title"></span>
        </h2>
        <p class="no-margin">
          {{ body }}
          <slot name="body"></slot>
        </p>
        <hr class="uc-hr my-32" />
        <div class="row align-items-center">
          <slot name="footer"></slot>
          <div class="col-md-12 text-right" v-show="closeButton">
            <a href="javascript:void(0)" class="uc-link uc-btn btn-inline" @click="close">
              Cerrar
              <i class="uc-icon icon-shape--rounded">close</i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    body: String,
    title: String,
    type: String,
    show: Boolean,
    closeButton: Boolean,
    caller: { type: String, default: "showModal" },
    icon: { type: String, default: "info" },
  },
  data() {
    return {
      display: "none",
      modal: this.caller,
    };
  },
  methods: {
    close() {
      this.$parent[this.caller] = false;
    },
    closeModal(event) {
      if (event.keyCode === 27) {
        if (this.$parent[this.caller]) {
          this.$parent[this.caller] = false;
          return;
        }
        this.$parent.showStandModal = false;
      }
    },
    keepOpen(event) {
      event.stopPropagation();
    },
  },
  mounted() {
    document.addEventListener("keyup", this.closeModal);
  },
  destroyed() {
    document.removeEventListener("keyup", this.closeModal);
  },
};
</script>
