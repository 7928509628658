<template>
  <div class="col-md-12 mb-20">
    <div class="uc-form-group">
      <label :for="fieldName">{{ title }}</label>
      <i class="uc-icon ml-4">{{ icon }}</i>
    </div>
    <div class="uc-form-group uc-radio-group">
      <input
        type="radio"
        :id="`${fieldName}_1`"
        :name="`${fieldName}`"
        value="1"
        class="uc-orig-radio"
        :checked="showTextarea"
        @change="showTextarea = true"
      />
      <label :for="`${fieldName}_1`" class="uc-radio-button">Sí</label>
      <input
        type="radio"
        :id="`${fieldName}_2`"
        :name="`${fieldName}`"
        value="0"
        class="uc-orig-radio"
        :checked="!showTextarea"
        @change="showTextarea = false"
      />
      <label :for="`${fieldName}_2`" class="uc-radio-button">No</label>
    </div>
    <div class="uc-form-group mt-20" v-show="showTextarea">
      <textarea
        v-model="value"
        :class="`uc-input-style w-icon-left ${required}`"
        v-tooltip="requiredTooltip"
        rows="3"
        :placeholder="`Narra brevemente ${placeholder}`"
        @change="setField({ id: fieldName, value })"
        @keydown="clearRequired(fieldName)"
      ></textarea>
      <i class="uc-icon">{{ icon }}</i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fieldName: String,
    icon: String,
    placeholder: String,
    title: String,
    clear: {
      type: Boolean,
      default: false,
    },
    required: String,
    requiredTooltip: String,
    model: String,
    show: Boolean,
    store: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: this.model,
      showTextarea: this.show,
    };
  },
  watch: {
    clear() {
      if (this.clear) {
        this.value = null;
        this.showTextarea = false;
      }
    },
    showTextarea() {
      this.value = this.showTextarea ? null : "emptyValue";
      this.$store.commit(`${this.store}/setField`, {
        id: this.fieldName,
        value: this.value,
      });
    },
  },
  methods: {
    setField(value) {
      this.$store.commit(`${this.store}/setField`, value);
    },
    clearRequired() {
      if (this.required) {
        this.$store.commit(`${this.store}/clearRequired`, this.fieldName);
      }
    },
  },
};
</script>
